.button-xpeaks-primary {
  background-size: contain;
  background-repeat: no-repeat;
  box-sizing: border-box;
  box-shadow: none;
  display: block;
  color: #000;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 24px 32px;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 0;
  transition: all 0.3s ease;
  color: #132441;
  height: fit-content;
  transform: scale(1);
  white-space: nowrap;
}

.button-xpeaks-primary:hover {
  transform: scale(1.05);
  filter: drop-shadow(0 10px 5px rgba(218, 245, 97, 0.2));
}
