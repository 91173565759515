.my-atropos {
  display: block;
}
.my-atropos .atropos-inner {
  border-radius: 1rem;
}
.my-atropos img {
  position: absolute;
  left: -5%;
  top: -5%;
  width: 110%;
  height: 110%;
  -o-object-fit: contain;
  object-fit: contain;
  display: block;
  z-index: 1;
  transform-style: preserve-3d;
  pointer-events: none;
  max-width: none;
}
.my-atropos img.atropos-spacer {
  position: relative;
  width: 100%;
  height: auto;
  left: 0;
  top: 0;
  visibility: hidden;
}
.my-atropos .atropos-button-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.75);
}
.my-atropos .atropos-button-wrap a {
  color: inherit;
  text-decoration: none;
}
.my-atropos .atropos-shadow {
  filter: blur(50px);
  opacity: 0.25;
}
.my-atropos .atropos-active .atropos-shadow {
  opacity: 1 !important;
}

.carousel-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  -webkit-overflow-scrolling: touch; /* Enables smooth scrolling on mobile */
}

.carousel-container::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}


