/* Tailwind CSS */

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.section {
  padding: 50px;
  background-color: #132441;
  z-index: 1;
}
